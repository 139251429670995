import React from 'react';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import { Button } from '../components/Button';

export const DownloadFile = ({
  fileName,
  buttonName,
}: {
  fileName: string;
  buttonName: string;
}) => {
  const downloadFile = async () => {
    const storage = getStorage();
    const fileRef = ref(storage, `logs/${fileName}`);

    try {
      const url = await getDownloadURL(fileRef);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error getting download URL:', error);
    }
  };

  return (
    <div className="m-y">
      <Button onClick={downloadFile}>{buttonName}</Button>
    </div>
  );
};
